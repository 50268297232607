import { useEffect, useState } from "react";

import { CanceledError, isAxiosError } from "axios";

import { useApiContext } from "../../../../api/context";
import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../utils";

export type Operation = {
  operationName: string;
  providerName: string;
  providerId: string;
  serviceName: string;
  serviceId: string;
  version: string;
  documentation?: string;
  description?: string;
};

export function useOperationsQuery<TOutput>(
  transform: (operation: Operation) => TOutput,
  query: string,
  provider?: string,
  service?: string
) {
  const { customer } = useCustomerContext();
  const showErrorSnackbar = useErrorSnackbar();
  const [queryResults, setQueryResults] = useState<TOutput[]>();
  const [loadingQuery, setLoadingQuery] = useState(false);
  const api = useApiContext();

  const trimmedQuery = query.trim();

  useEffect(() => {
    if (trimmedQuery === "") {
      return;
    }

    const controller = new AbortController();
    const timer = setTimeout(async () => {
      setLoadingQuery(true);
      try {
        const { data } = await api.request<Operation[] | null>({
          method: "get",
          url: `/v1/customers/${customer.id}/cloudflow/operations`,
          params: {
            query: trimmedQuery,
            provider,
            service,
          },
          signal: controller.signal,
        });
        setQueryResults(data?.map(transform) ?? []);
      } catch (exception) {
        if (!(exception instanceof CanceledError)) {
          consoleErrorWithSentry(exception);
          let errorMessage = "Unknown error. Please try again.";
          if (isAxiosError(exception)) {
            errorMessage = JSON.stringify(exception.response?.data);
          } else if (exception instanceof Error) {
            errorMessage = exception.message;
          }
          showErrorSnackbar(errorMessage);
        }
      } finally {
        setLoadingQuery(false);
      }
    }, 250);

    return () => {
      setLoadingQuery(false);
      clearTimeout(timer);
      controller.abort();
    };
  }, [api, customer.id, provider, trimmedQuery, service, showErrorSnackbar, transform]);

  return [queryResults, loadingQuery] as const;
}
