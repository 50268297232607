import { useCallback, useMemo } from "react";

import {
  type CloudFlowNodeType,
  type Member,
  type ReferencedNodeValue,
  type StructureApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import cloneDeep from "lodash/cloneDeep";

import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { getCloudIcon } from "../../../../utils/common";
import type { NodeConfigs } from "../../types";

export function stripControlledFields(
  inputModel: StructureApiServiceModelDescriptor<Member>,
  controlledInputs: string[]
) {
  const inputCopy = cloneDeep(inputModel);

  controlledInputs.forEach((field) => {
    if (field in inputCopy.members) {
      delete inputCopy.members[field];
    }
  });

  return inputCopy;
}

export function useApiProviderLogo() {
  const isDarkTheme = useDarkThemeCheck();
  const iconSet = useMemo(
    () => ({
      AWS: getCloudIcon("amazon-web-services", isDarkTheme),
      GCP: getCloudIcon("google-cloud", isDarkTheme),
      DoiT: "https://storage.googleapis.com/hello-static-assets/emoji/doit-mark.png",
    }),
    [isDarkTheme]
  );

  return useCallback((provider: string): string | undefined => iconSet[provider], [iconSet]);
}

export function getFieldRef(nodeConfig: NodeConfigs<CloudFlowNodeType>): ReferencedNodeValue | undefined {
  return "referencedNodeId" in nodeConfig.parameters
    ? {
        referencedNodeId: nodeConfig.parameters.referencedNodeId,
        referencedField: nodeConfig.parameters.referencedField,
      }
    : undefined;
}
