import "@xyflow/react/dist/style.css";

import { useEffect, useState } from "react";

import { Prompt } from "react-router";
import { CloudFlowNodeType, type CloudFlowProvider } from "@doitintl/cmp-models";
import { Alert, Box, Stack, useTheme } from "@mui/material";
import { Controls, type EdgeTypes, type NodeTypes, ReactFlow, ReactFlowProvider } from "@xyflow/react";

import { useFullScreen } from "../../../utils/dialog";
import CloudflowSidePanel from "../CloudflowSidePanel/SidePanelContent";
import { ChangeTriggerDialog } from "../Dialog/ChangeTriggerDialog";
import { DeleteIfNodeDialog } from "../Dialog/DeleteIfNodeDialog";
import { ManageIfNodeDialog } from "../Dialog/ManageIfNodeDialog";
import { EDGE_TYPE } from "../types";
import { ActionSearchDialog } from "./ActionSearchDialog/ActionSearchDialog";
import { NodeEdgeManagerProvider, useNodeEdgeManager } from "./Common/NodeEdgeManagerProvider";
import ConditionEdge from "./Edge/ConditionEdge";
import CustomEdge from "./Edge/CustomEdge";
import GhostEdge from "./Edge/GhostEdge";
import { ActionNode } from "./Node/ActionNode";
import { ConditionNode } from "./Node/ConditionNode";
import { FilterNode } from "./Node/FilterNode";
import { GhostNode } from "./Node/GhostNode";
import { StartStepConfigurator } from "./Node/StartStepConfigurator";
import { TransformNode } from "./Node/TransformNode";
import { TriggerNode } from "./Node/TriggerNode";
import Topbar from "./Topbar/Topbar";

const edgeTypes: EdgeTypes = {
  [EDGE_TYPE.CUSTOM]: CustomEdge,
  [EDGE_TYPE.GHOST]: GhostEdge,
  [EDGE_TYPE.CONDITION]: ConditionEdge,
};

const nodeTypes: NodeTypes = {
  [CloudFlowNodeType.START_STEP]: StartStepConfigurator,
  [CloudFlowNodeType.GHOST]: GhostNode,
  [CloudFlowNodeType.TRIGGER]: TriggerNode,
  [CloudFlowNodeType.MANUAL_TRIGGER]: TriggerNode,
  [CloudFlowNodeType.ACTION]: ActionNode,
  [CloudFlowNodeType.CONDITION]: ConditionNode,
  [CloudFlowNodeType.TRANSFORMATION]: TransformNode,
  [CloudFlowNodeType.FILTER]: FilterNode,
};

export const CloudflowEditorContent = () => {
  const theme = useTheme();

  const { isMobile } = useFullScreen("sm");

  const {
    nodes,
    edges,
    onConnect,
    onEdgeClick,
    focusedNodeId,
    showModal,
    closeModal,
    isTriggerDialogVisible,
    closeChangeTriggerDialog,
    manageIfActionsId,
    setManageIfActionsId,
    onSaveManageIfActionsDialog,
    onConfirmDeleteIfNode,
    deleteIfNodeId,
    setDeleteIfNodeId,
    handleNodeOperation,
    interactionEnabled,
    onConfirmChangeTrigger,
    httpOperationLoading,
    algoliaOperationType,
    onNodesChange,
    onEdgesChange,
    activeNode,
  } = useNodeEdgeManager();

  const hasUnsavedChanges = nodes?.some((n) => n.data.touched);

  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (!hasUnsavedChanges) return;
      e.preventDefault();

      // recommended by MDN for compatability with older browsers
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#examples
      // eslint-disable-next-line deprecation/deprecation
      e.returnValue = true;
    };

    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [hasUnsavedChanges]);

  return (
    <>
      <style>
        {`
      #main-layout{
        >[role="alert"],>header {
          display: none;
        }
      }
      `}
      </style>
      <Prompt when={hasUnsavedChanges} message={"Changes that you made may not be saved."} />
      <Stack
        sx={{
          backgroundColor: theme.palette.general.backgroundDark,
          mx: -2,
          mt: -1,
          pt: 0,
          pb: 1,
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <Topbar />
        <Box width="100%" height="100%">
          {isMobile && (
            <Alert severity="info" sx={{ position: "relative", top: 64, zIndex: 1, left: 16, mr: 4 }}>
              To get the best out of CloudFlow please use a device with a larger screen resolution such as a desktop or
              laptop.
            </Alert>
          )}
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            onEdgeClick={onEdgeClick}
            fitView
            fitViewOptions={{ maxZoom: 1 }}
            nodesDraggable={false}
            onlyRenderVisibleElements={true}
            style={{ transition: "margin 0.3s", marginRight: activeNode ? "450px" : "0" }}
            deleteKeyCode={null}
            zoomOnScroll={interactionEnabled}
            panOnDrag={interactionEnabled}
          />
          <Controls orientation="horizontal" showInteractive={false} style={{ direction: "rtl" }} />
        </Box>
      </Stack>
      {!!deleteIfNodeId && (
        <DeleteIfNodeDialog
          open={!!deleteIfNodeId}
          onConfirm={onConfirmDeleteIfNode}
          cloudflowLoading={httpOperationLoading}
          onCancel={() => {
            setDeleteIfNodeId("");
          }}
        />
      )}
      {!!manageIfActionsId && (
        <ManageIfNodeDialog
          open={!!manageIfActionsId}
          onCancel={() => {
            setManageIfActionsId("");
          }}
          onSave={onSaveManageIfActionsDialog}
          cloudflowLoading={httpOperationLoading}
        />
      )}
      {showModal && !!focusedNodeId && (
        <ActionSearchDialog
          handleCloseDialog={closeModal}
          onOperationSelect={({ operationName, provider, service, version }) => {
            handleNodeOperation(
              focusedNodeId,
              {
                objectID: operationName,
                operationName,
                provider: provider as unknown as CloudFlowProvider,
                serviceName: service,
                serviceNameShort: service,
                versionId: version,
              },
              algoliaOperationType
            );
          }}
        />
      )}
      {activeNode && isTriggerDialogVisible && (
        <ChangeTriggerDialog
          nodeType={activeNode.type as CloudFlowNodeType}
          open={!!activeNode}
          onCancel={closeChangeTriggerDialog}
          onConfirm={onConfirmChangeTrigger}
        />
      )}
    </>
  );
};

export const CloudflowEditor = () => {
  const [isTriggerDialogVisible, setIsTriggerDialogVisible] = useState(false);

  return (
    <ReactFlowProvider>
      <NodeEdgeManagerProvider
        isTriggerDialogVisible={isTriggerDialogVisible}
        setIsTriggerDialogVisible={setIsTriggerDialogVisible}
      >
        <CloudflowEditorContent />
        <CloudflowSidePanel setShowChangeTriggerDialog={setIsTriggerDialogVisible} />
      </NodeEdgeManagerProvider>
    </ReactFlowProvider>
  );
};
